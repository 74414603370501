// About us code goes here
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../footer/Footer';
import emmanuel from '../../images/Emma2.jpg';
import yaw from '../../images/Yaw2.jpg';
import crownie from '../../images/Crownie2.jpg';
import kofi from '../../images/kofi.jpeg';
import abednego from '../../images/abednego.jpg';
import jessica from '../../images/Jessica2.jpg';
import HomeNavigation from '../navigation/homeNavigation';
import SectionHeader from '../sectionheader'
import TeamMember from '../teammember';



const team = [
  { name: 'Yaw Takyi', position: 'Founder & CEO ', title:'Snr Developer', image: yaw },
  { name: 'Crownie Eme', position: 'Cofounder & CTO ', title:'Snr Developer', image: crownie },
  { name: 'Kofi Sika Kumi', position: 'Snr Developer', title:'', image: kofi },
  { name: 'Emmanuel Asante', position: 'UI/UX Designer', title:'', image: emmanuel },

  // { name: 'Jessica Ansah', position: ' Marketer',title:'', image: jessica },

];
const About = () => (
  <div className='flex flex-col overflow-x-hidden'>
    <HomeNavigation />

    {/* Content Sections */}
    <div className="pt-[85px] md:pt-[117px] lg:pt-[151px] md:pt-[142px] px-[32px]  md:px-[44px] lg:px-[67px] xl:px-[102px] h-min-screen "> {/* Padding top to avoid overlap with fixed navbar */}

      {/* Section 1 */}
      <section className=" h-fit flex flex-col items-center justify-center gap-[18px] md:gap-0  ">

        <SectionHeader title="About Us "
          subtitle="The Modenbo Story"
          description="Modenbo Technologies was founded by two visionary entrepreneurs, Yaw and Crownie, driven by a shared passion to connect local insights with global innovation. Their mission is simple yet powerful: to empower businesses and individuals with advanced software solutions that make a real difference. By blending world-class expertise with a deep understanding of the African market, Modenbo has become a driving force in the tech space.
            What started as a small venture is quickly evolving into a movement, reshaping the way technology can address real-world challenges. Whether it's in fintech, e-commerce, or EdTech, Modenbo provides scalable, value-driven solutions that help businesses thrive in today's competitive landscape.
            At Modenbo we work closely with our clients to ensure every project not only drives transformation but also builds trust and creates lasting impact.
            "
          descriptionClass=' md:px-[30px] lg:px-[74px] xl:px-[102px]'
          SectionClass='pt-[28px] pb-[22px] md:pt-[32px] md:pb-[40px] lg:pt-[78px] lg:pb-[84px] xl:pt-[58px] xl:pb-[68px]'
        />
        <div className='grid grid-cols-1 md:grid-cols-3 gap-y-[34px] md:gap-x-[13px] md:pt-[32px] md:pb-[40px] lg:pt-[54px] lg::pb-[45px] xl:pt-0 xl:pb-0 md:items-start'>

          <SectionHeader title="Our Mission "
            subtitleSmall="Leading the way in software development"
            description="To empower businesses and everyday people with innovative, data-driven software solutions."
            subtitleSmallClass='px-[58px] md:px-[8px]'
            descriptionClass='px-[17px] md:px-[15px]'
            SectionClass='pt-[29px] pb-[40px] xl:pt-[0]  '

          />

          <SectionHeader title="Values "
            subtitleSmall="Innovation, Excellence & Creativity"
            description="To be a globally recognized African technology company, known for pioneering innovative solutions that foster economic growth and community advancement across borders.
"
            subtitleSmallClass='px-[57px] md:px-[8px]'
            descriptionClass='px-[15px] md:px-[15px]'
            SectionClass='pt-[29px] pb-[40px] xl:pt-[0]'
          />

          <SectionHeader title="Core Strength "
            subtitleSmall="Goal Oriented & Focused"
            description="Modenbo offers solutions that blend local expertise with global standards, allowing international clients to benefit from African insights and talent. It combines consulting and software development for a more strategic partnership in digital transformation. "
            descriptionClass='px-[15px] md:px-[15px]'
            subtitleSmallClass='px-[84px] md:px-[45px]'
            SectionClass='pt-[29px] pb-[40px] xl:pt-[0]'

          />
        </div>
        <div className='flex flex-col gap-[77px] md:gap-[74px] pb-[61px] md:pt-[54px] md:pt-[76px] lg:pt-0 lg:pb-[80px] '>

          <SectionHeader title="The Team"
            subtitle="Meet our skilled professionals"
            description="Our experienced team blends innovation and creativity to design software solutions that drive growth, efficiency, and competitive advantage."
            descriptionClass='md:px-[113px]'
            SectionClass='pt-[44px] '
          />

          <div className="grid grid-cols-2  sm:grid-cols-3 xl:grid-cols-4 gap-x-[19px] md:gap-x-[13px] gap-y-[24px] md:gap-y-[51px] md:gap-x-[25px]  lg:gap-y-[44px] ">
            {team.map((person, index) => (
              <TeamMember key={index}
                image={person.image}
                name={person.name}
                position={person.position}
                title={person.title}
              />
            ))}
            {/* Add more team members as needed */}
          </div>
        </div>



      </section>





    </div>

    <Footer />

  </div>

  // <>
  //   <Helmet>
  //     <meta property="og:title" content="About Us" />
  //     <meta property="og:description" content={"We are engineering the future with technology by reshaping industries uplifting communities and pushing the boundaries of what's achievable"} />
  //     <meta property="og:url" content="https://www.modenbo.com/about" />
  //     <meta property="og:type" content="website" />
  //   </Helmet>
  //   <HomeNavigation />
  //   <img src={aboutLeft} alt="" className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full z-[-1] mt-[320px] ml-[-20px] absolute" />
  //   <div className=" w-full px-[32px] sm:px-[122px] flex flex-col justify-center items-center">
  //     <div className=" mt-[65px] sm:mt-[120px] text-center">
  //       <h1 className=" font-bold text-[30px] sm:text-[50px] leading-normal">
  //         The Modenbo Story
  //       </h1>
  //       <h1 className=" text-[#707070] mt-[23px] sm:mt-10 text-[16px] sm:text-[18px]">
  //         Our journey started with a shared vision – a
  //         vision to drive innovation and elevate technology&apos;s
  //         role in society. As a passionate team of trailblazers,
  //         Modenbo was born, and we&apos;ve ceaselessly pursued the extraordinary,
  //         fuelled by the belief that every hurdle is an opportunity and every
  //         challenge is a chance to reshape the future.
  //       </h1>
  //       <h1 className=" mt-[111px] sm:mt-[122px] font-bold text-[30px] sm:text-[50px] leading-normal mb-[34px] sm:mb-[109px]">
  //         Team Members
  //       </h1>
  //       <div className=" w-full flex flex-wrap gap-[34px] sm:gap-[80px] mb-[185px] sm:mb-[245px] justify-center">
  //         {team.map((person) => (
  //           <div key={person} className=" flex flex-col">
  //             <div className=" w-[250px] sm:w-[345px] h-[250px] sm:h-[345px] bg-[#D9D9D9] rounded-[250px] sm:rounded-[345px]">
  //               <img src={person.image} alt="" className="w-[250px] sm:w-[345px] h-[250px] sm:h-[345px] rounded-[250px] sm:rounded-[345px] object-cover" />
  //             </div>
  //             <h1 className=" text-[16px] sm:text-[18px] mt-5">{person.name}</h1>
  //             <h1 className="text-[16px] sm:text-[18px] text-[#707070] ">{person.position}</h1>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  //   <div className="flex flex-row-reverse z-[-1] relative">
  //     <img src={aboutRight} alt="" className=" -mt-[35rem] -mr-[20px] w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full float-right z-[-1] absolute" />
  //   </div>
  //   <Footer />
  // </>
);

export default About;
